import {
  GridApi,
  ICellRendererParams,
  ServerSideTransaction,
} from "@ag-grid-community/core";
import {
  CustomCellRendererProps,
  CustomLoadingCellRendererProps,
} from "@ag-grid-community/react";
import { ButtonProps, Classes, Intent, SpinnerSize } from "@blueprintjs/core";
import {
  CollectionHexLinkId,
  CollectionId,
  HexId,
  HexType,
  ProjectRole,
  SpecialVersionType,
} from "@hex/common";
import { orderBy } from "lodash";
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router";
import styled, { css } from "styled-components";

import {
  HexAnchorButton,
  HexButton,
  HexCheckbox,
  HexNonIdealState,
  HexNonIdealStateContainer,
  HexNonIdealStateProps,
  HexSpinner,
  HexTag,
  HexTooltip,
  IconOnlyButton,
  Txt,
} from "../../../hex-components";
import { useOnClickProps } from "../../../hooks/useOnClickProps.js";
import {
  MinimalCategory,
  MinimalStatus,
} from "../../../hooks/useProjectLabelsForHex.js";
import { useTerminology } from "../../../hooks/useTerminology.js";
import { Routes } from "../../../route/routes.js";
import { useDialog } from "../../../util/dialogs.js";
import { evtModKey } from "../../../util/Keys.js";
import { HardDeleteWarningTag } from "../../common/HardDeleteWarningTag.js";
import { HumanDate } from "../../common/HumanDate.js";
import { CategoryLabel } from "../../common/labels/CategoryLabel.js";
import { StatusLabel } from "../../common/labels/StatusLabel.js";
import { ProjectCardTypeIcon } from "../../common/project-cards/ProjectCardTypeIcon.js";
import {
  ShowOnHover,
  ShowOnHoverContainer,
  StickyShowOnHover,
} from "../../common/ShowOnHover.js";
import { StarIconCheckbox } from "../../common/StarIconCheckbox.js";
import { useToaster } from "../../common/Toasts.js";
import { FeatureGateToolTip } from "../../feature-gate/FeatureGateToolTip.js";
import {
  ViewCountDuration,
  humanReadableViewCountDuration,
} from "../../hex-list/ViewCountDuration.js";
import { CollectionProjectRoleDropdown } from "../../hex-list-2/hex-row/CollectionProjectRoleDropdown.js";
import { HexRow2Fragment } from "../../hex-list-2/hex-row/HexRow2.generated.js";
import { HexRowOptionsMenuContents } from "../../hex-list-2/hex-row/HexRowOptionsMenuContents.js";
import { useShouldShowAdvancedProjectViewStats } from "../../hex-list-2/hex-row/hooks/useShouldShowViewPublishedAppStats.js";
import { useStarHex } from "../../hex-list-2/hex-row/hooks/useStarHex.js";
import { TrashOptionsMenuContents } from "../../hex-list-2/hex-row/TrashOptionsMenuContents.js";
import { UnknownHexRowFragment } from "../../hex-list-2/hex-row/UnknownHexRow.generated.js";
import {
  getHexRoute,
  getViewPublishedAppStatsDialogId,
  isCollectionHexLink,
  isUnknownHex,
} from "../../hex-list-2/hex-row/utils.js";
import { CollectionHexLink2Fragment } from "../../hex-list-2/HexList2.generated.js";
import { CollectionEmoji } from "../../home/collections-tab/shared/CollectionName.js";
import { ScreenReaderOnly } from "../../home/shared/ScreenReaderOnly.js";
import {
  EditIcon,
  MoreMenuIcon,
  TickIcon,
  TrendingIcon,
} from "../../icons/CustomIcons.js";
import { Avatar } from "../../user/Avatar.js";
import { ProjectsTableSafeOrUnknownHexFragment } from "../ProjectsTable.generated.js";

import {
  FlexCategoryLabel,
  LoadingCellWrapper,
  MoreActionsButtonGroup,
  MoreActionsMenuPopover,
  ProjectTitleAnchor,
  RowHeaderTableCellText,
  TableCellText,
  TableRowProjectTypeIcon,
  ViewNotebookButton,
} from "./styled.js";
import { getRelativeUrlForExplore, getRelativeUrlForProject } from "./utils.js";

// Note: React.memo not needed since component accepts no props
// Note: saving the emdash for differentiating between "does not apply" and no value
// const EmDash: React.FunctionComponent = () => <>—</>;
const EmptyCell: React.FunctionComponent = () => null;
export const NullishCellValue: React.FunctionComponent = () => <EmptyCell />;

// Number of records to show in the column before demoting others to a tooltip
const OVERFLOW_TOOLTIP_COUNT = 2;

export const HexTypeCellRenderer = React.memo<{
  data: HexRow2Fragment | CollectionHexLink2Fragment;
  value: HexType;
}>(function HexTypeCellRenderer({ data, value: hexType }) {
  if (data == null) {
    return <NullishCellValue />;
  }
  const hex: HexRow2Fragment | UnknownHexRowFragment = isCollectionHexLink(data)
    ? data.safeOrUnknownHex
    : data;

  const isUnknown = isUnknownHex(hex);
  const isPublished = isUnknown ? false : hex.lastPublishedVersion != null;

  return (
    <HexTooltip
      content={`This ${
        hexType === HexType.COMPONENT ? "component" : "project"
      } has not been shared with you`}
      css={`
        .${Classes.POPOVER_TARGET} {
          padding-right: 10px;
        }
      `}
      disabled={!isUnknown}
      targetTagName="span"
    >
      <ProjectCardTypeIcon
        hexType={hexType}
        isPublished={isPublished}
        isUnknownHex={isUnknown}
      />
    </HexTooltip>
  );
});

export const LinkedNameWithActionsMenuRenderer = React.memo<
  Pick<
    HexRow2Fragment,
    | "archivedDate"
    | "unarchivedDate"
    | "canDelete"
    | "canEdit"
    | "canViewLogic"
    | "currentDraft"
    | "hexType"
    | "id"
    | "lastPublishedVersion"
    | "projectLanguage"
    | "starredByViewer"
    | "canSetOwner"
    | "owner"
  > & {
    canSelect: boolean;
    canRemoveFromCollection: boolean;
    href: string;
    isComponent: boolean;
    isPublished: boolean;
    isTrashed: boolean;
    node: CustomCellRendererProps["node"];
    showDraftEditButton: boolean;
    value: { starredByViewer: boolean; title: string };
    version: SpecialVersionType;
    api: GridApi;
  }
>(function LinkedNameWithActionsMenuRenderer({
  api,
  archivedDate,
  canDelete,
  canEdit,
  canRemoveFromCollection,
  canSelect,
  canSetOwner: userCanEditProjectOwner,
  canViewLogic,
  currentDraft,
  hexType,
  href,
  id,
  isComponent,
  isPublished,
  isTrashed,
  lastPublishedVersion,
  node,
  owner,
  projectLanguage,
  showDraftEditButton,
  unarchivedDate,
  value: { starredByViewer, title },
  version,
}) {
  const canSetOwner = userCanEditProjectOwner;
  const route = getHexRoute({ hexType, canViewLogic }, isPublished);

  const primaryCardAnchorProps = useOnClickProps({
    replace: false,
    to:
      hexType === HexType.EXPLORE
        ? getRelativeUrlForExplore(id)
        : getRelativeUrlForProject(route, id, version),
  });

  const editAnchorProps = useOnClickProps({
    replace: false,
    to: Routes.LOGIC.getUrl({
      hexId: id,
      version: SpecialVersionType.DRAFT,
    }),
  });

  const handleOnRemoveFromCollectionSuccess = useCallback(() => {
    const transaction: ServerSideTransaction = {
      remove: [node.data],
    };

    api.applyServerSideTransaction(transaction);
  }, [node, api]);

  const handleOnMenuClick: React.MouseEventHandler = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const content = useMemo(
    () =>
      isTrashed ? (
        <TrashOptionsMenuContents hexId={id} isComponent={isComponent} />
      ) : (
        <HexRowOptionsMenuContents
          archivedDate={archivedDate}
          canDelete={canDelete}
          canEdit={canEdit}
          canRemoveFromCollection={canRemoveFromCollection}
          canSetOwner={canSetOwner}
          canViewLogic={canViewLogic}
          currentDraft={currentDraft}
          hexType={hexType}
          href={href}
          id={id}
          lastPublishedVersion={lastPublishedVersion}
          owner={owner}
          projectLanguage={projectLanguage}
          unarchivedDate={unarchivedDate}
          onMenuClick={handleOnMenuClick}
          onSuccessRemoveTableRow={handleOnRemoveFromCollectionSuccess}
        />
      ),
    [
      isTrashed,
      archivedDate,
      canDelete,
      canEdit,
      canViewLogic,
      currentDraft,
      hexType,
      href,
      owner,
      lastPublishedVersion,
      id,
      projectLanguage,
      handleOnRemoveFromCollectionSuccess,
      isComponent,
      canSetOwner,
      handleOnMenuClick,
      canRemoveFromCollection,
      unarchivedDate,
    ],
  );

  const toaster = useToaster();

  const handleOnStarChange = useCallback(
    (_id, currentValue) => {
      node.updateData({ ...node.data, starredByViewer: currentValue });
    },
    [node],
  );

  const handleStarHexOnError = useCallback(
    (isStarred) => {
      toaster.show({
        message: isStarred
          ? "There was an error removing this project from your favorites."
          : "There was an error adding this project to your favorites.",
        intent: Intent.DANGER,
      });
    },
    [toaster],
  );
  const handleStarIconCheckboxChange = useStarHex(
    id,
    handleStarHexOnError,
    handleOnStarChange,
  );

  const handleOnPopoverClick: MouseEventHandler = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const moreActionsMenuPopoverRenderTarget = useCallback(
    ({ ref, ...props }) => {
      return (
        <StickyShowOnHover
          $show={props.isOpen || undefined}
          css={`
            margin-left: auto;
          `}
          data-controller={id}
          onClick={handleOnPopoverClick}
        >
          <MoreActionsButtonGroup>
            {showDraftEditButton ? (
              <ViewNotebookButton
                {...editAnchorProps}
                icon={<EditIcon />}
                minimal={false}
                small={true}
                subtle={true}
                text="Edit"
              />
            ) : null}
            <MoreActionsButton {...props} elementRef={ref} />
          </MoreActionsButtonGroup>
        </StickyShowOnHover>
      );
    },
    [id, handleOnPopoverClick, showDraftEditButton, editAnchorProps],
  );

  const handleOnContainerClick: MouseEventHandler<HTMLElement> = useCallback(
    (evt) => {
      if (api.getSelectedNodes().length > 0) {
        return;
      }

      if (evtModKey(evt)) {
        window.open(primaryCardAnchorProps.href, "_blank");
      } else {
        primaryCardAnchorProps.onClick(evt);
      }
    },
    [api, primaryCardAnchorProps],
  );

  const handleOnLinkClick: MouseEventHandler<HTMLElement> = useCallback(
    (evt) => {
      evt.stopPropagation();
      primaryCardAnchorProps.onClick(evt);
    },
    [primaryCardAnchorProps],
  );

  return (
    <LinkedNameContainer
      {...primaryCardAnchorProps}
      id={id}
      onClick={handleOnContainerClick}
    >
      {!canSelect && (
        <TableRowProjectTypeIcon
          hexType={hexType}
          isPublished={isPublished}
          isUnknownHex={false}
        />
      )}

      <ProjectTitleAnchor
        {...primaryCardAnchorProps}
        className={Classes.TEXT_OVERFLOW_ELLIPSIS}
        onClick={handleOnLinkClick}
      >
        <RowHeaderTableCellText $isUnknownHex={false}>
          {title}
        </RowHeaderTableCellText>
      </ProjectTitleAnchor>
      {hexType !== HexType.EXPLORE && (
        <>
          <ShowOnHover
            $show={starredByViewer}
            css={`
              display: flex;
              padding-left: 2px;
              padding-right: 1px;
              padding-bottom: 2px;
            `}
            data-controller={id}
          >
            <StarIconCheckbox
              id={`star-checkbox-table-${id}`}
              starred={starredByViewer}
              onChange={handleStarIconCheckboxChange}
            />
          </ShowOnHover>
          <MoreActionsMenuPopover
            canEscapeKeyClose={true}
            content={content}
            position="right-top"
            renderTarget={moreActionsMenuPopoverRenderTarget}
          />
        </>
      )}
    </LinkedNameContainer>
  );
});

const LinkedNameContainer = styled(ShowOnHoverContainer)`
  cursor: pointer;
  display: flex;
  align-items: center;
  && ${Classes.BUTTON} {
    background-color: ${({ theme }) => theme.NonTransparentHoverColor};
  }

  ${TableRowProjectTypeIcon} {
    padding-right: 8px;
  }
`;

export const ReadonlyNameCellRenderer = React.memo<{
  hexType: HexType;

  ownerName: string;
}>(function ReadonlyNameCellRenderer({ hexType, ownerName }) {
  const text = useMemo(
    () =>
      `${hexType === HexType.COMPONENT ? "Component" : "Project"} ${`owned by ${ownerName}`}`,
    [hexType, ownerName],
  );
  return (
    <ReadonlyNameContainer>
      <RowHeaderTableCellText $isUnknownHex={true}>
        {text}
      </RowHeaderTableCellText>
    </ReadonlyNameContainer>
  );
});

const ReadonlyNameContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${TableRowProjectTypeIcon} {
    padding-right: 8px;
  }
`;

export const StatusTableCellRenderer = React.memo<{
  value?: MinimalStatus | null;
}>(function StatusTableCellRenderer({ value }) {
  if (value == null) {
    return <NullishCellValue />;
  }

  return (
    <TableCellText
      css={`
        display: inline-flex;
        vertical-align: middle;
      `}
    >
      <StatusLabel muted={!value.endorsed} status={value} />
    </TableCellText>
  );
});

const MoreTooltipContentsWrapper = styled.span`
  display: flex;
  gap: 4px;
  align-items: center;
  min-width: 0;
  flex-wrap: wrap;
  justify-content: center;
`;

const MoreTooltip = styled(HexTooltip)`
  &.${Classes.POPOVER_TARGET} {
    display: inline-flex;
    vertical-align: middle;
  }
`;

const CollapsedHexTag = styled(HexTag)`
  &&& {
    background-color: transparent;
    color: ${({ theme }) => theme.fontColor.MUTED};
    font-size: ${({ theme }) => theme.fontSize.SMALL};
    padding: 1px 2px;
  }
`;

export const CollapsedCategoriesTableCellRenderer = React.memo<{
  value: MinimalCategory[];
}>(function CollapsedCategoriesTableCellRenderer({ value: categories }) {
  const expandedCategories = useMemo(
    () => categories.slice(0, OVERFLOW_TOOLTIP_COUNT),
    [categories],
  );
  const sliceOfCategories = useMemo(
    () => categories.slice(OVERFLOW_TOOLTIP_COUNT),
    [categories],
  );
  const tooltipCategories = useMemo(() => {
    return sliceOfCategories.map(({ color, id, name }) => (
      <React.Fragment key={id}>
        <CategoryLabel
          category={{
            color,
            id,
            name,
            description: null,
          }}
        />
      </React.Fragment>
    ));
  }, [sliceOfCategories]);

  return (
    <>
      <CategoriesTableCellRenderer value={expandedCategories} />
      <MoreTooltip
        content={
          <MoreTooltipContentsWrapper>
            {tooltipCategories}
          </MoreTooltipContentsWrapper>
        }
        position="bottom"
      >
        <CollapsedHexTag intent={Intent.NONE}>
          +{sliceOfCategories.length}
          <ScreenReaderOnly>More categories</ScreenReaderOnly>
        </CollapsedHexTag>
      </MoreTooltip>
    </>
  );
});

const CategoryTableCellText = styled(TableCellText)`
  display: inline-flex;
  min-width: 0;
  vertical-align: middle;
  overflow: hidden;

  .${Classes.POPOVER_TARGET} {
    overflow: hidden;
  }
`;

export const CategoriesTableCellRenderer = React.memo<{
  value: MinimalCategory[];
}>(function CategoriesTableCellRenderer({ value: categories }) {
  return (
    <>
      {categories.map((category) => (
        <CategoryTableCellText key={category.id}>
          <FlexCategoryLabel category={category} muted={true} />
        </CategoryTableCellText>
      ))}
    </>
  );
});

export const RequiresReviewTableCellRenderer = React.memo<{
  value?: {
    requiresReview: boolean;
    resolvedRequiresReview: boolean;
    status:
      | (MinimalStatus & {
          enforcesReview: boolean;
        })
      | null;
  };
}>(function RequiresReviewTableCellRenderer({ value }) {
  if (value == null) {
    return <NullishCellValue />;
  }

  const { requiresReview, resolvedRequiresReview, status } = value;
  if (!resolvedRequiresReview) {
    return <NullishCellValue />;
  } else if (requiresReview) {
    return (
      <HexTooltip content="Review required by project settings">
        <TickIcon />
      </HexTooltip>
    );
  } else if (status != null && status.enforcesReview) {
    return (
      <StatusLabel
        minimal={true}
        muted={true}
        status={{
          ...status,
          description: `Review required by status`,
        }}
      />
    );
  }

  return <NullishCellValue />;
});

export const GenericTableCellRenderer = React.memo<{
  value?: string;
  valueFormatted?: string | undefined;
}>(function GenericTableCellRenderer({ value, valueFormatted }) {
  if (value == null) {
    return <NullishCellValue />;
  }
  return <TableCellText>{valueFormatted ?? value}</TableCellText>;
});

export const GenericCheckboxCellRenderer = React.memo<{
  value?: boolean;
  valueFormatted?: string;
}>(function GenericCheckboxCellRenderer({ value }) {
  if (value == null || value === false) {
    return <NullishCellValue />;
  }
  return <TickIcon />;
});

const AdvancedProjectStatsFeatureGateTooltip: React.FC = ({ children }) => (
  <FeatureGateToolTip
    content="enable sorting by publishing views"
    disabled={false}
    featureGate="advancedProjectViewStats"
    fill={true}
    hoverOpenDelay={200}
    lazy={true}
    shouldStopPropagation={true}
  >
    {children}
  </FeatureGateToolTip>
);

const AdvancedProjectStatsInfoTooltip: React.FC = ({ children }) => (
  <HexTooltip
    content="View stats"
    disabled={false}
    fill={true}
    hoverOpenDelay={200}
    lazy={true}
    placement="bottom"
    targetTagName="div"
  >
    {children}
  </HexTooltip>
);

const TooltipTargetWrapper = styled.span`
  height: 100%;
  display: flex;
  & .${Classes.POPOVER_TARGET} {
    align-self: center;
  }
`;

export const AppViewsTableCellRenderer = React.memo<{
  id: HexId;
  value: number;
  viewCountDuration: ViewCountDuration;
}>(function AppViewsTableCellRenderer({ id, value, viewCountDuration }) {
  const showAdvancedProjectStats = useShouldShowAdvancedProjectViewStats();
  const { openDialog } = useDialog(getViewPublishedAppStatsDialogId());

  const setHexIdQueryParam = useCallback(
    (queryParams) => {
      queryParams.set("hexId", id);
    },
    [id],
  );

  const handleOpenDialog = useCallback(() => {
    openDialog({ beforeSetDialogQueryParam: setHexIdQueryParam });
  }, [openDialog, setHexIdQueryParam]);

  const readableDuration = humanReadableViewCountDuration(viewCountDuration);

  const TooltipComponent = showAdvancedProjectStats
    ? AdvancedProjectStatsInfoTooltip
    : AdvancedProjectStatsFeatureGateTooltip;

  const { appViewText } = useTerminology();
  return (
    <TooltipTargetWrapper>
      <TooltipComponent>
        <HexAnchorButton
          disabled={!showAdvancedProjectStats}
          extraSmall={true}
          fill={true}
          icon={<TrendingIcon color="currentColor" />}
          intent={Intent.NONE}
          minimal={true}
          text={
            <>
              {value}
              <ScreenReaderOnly>
                {appViewText} ({readableDuration})
              </ScreenReaderOnly>
            </>
          }
          onClick={handleOpenDialog}
        />
      </TooltipComponent>
    </TooltipTargetWrapper>
  );
});

export const CollectionProjectRoleTableCellRenderer = React.memo<{
  canEditCollection: boolean;
  collectionHexLinkId: CollectionHexLinkId;
  collectionId: CollectionId;
  hex: Pick<
    HexRow2Fragment,
    "id" | "canShare" | "hexType" | "maxGrantableRole"
  >;
  node: CustomCellRendererProps["node"];
  value: { projectRole: ProjectRole | null };
}>(function CollectionProjectRoleTableCellRenderer({
  canEditCollection,
  collectionHexLinkId,
  collectionId,
  hex,
  node,
  value: { projectRole },
}) {
  const handleOnChange = useCallback(
    (selectedRole) => {
      node.updateData({
        ...node.data,
        collectionHexLink: {
          ...node.data.collectionHexLink,
          projectRole: selectedRole,
        },
      });
    },
    [node],
  );
  return (
    <CollectionProjectRoleDropdown
      canEditCollection={canEditCollection}
      collectionHexLinkId={collectionHexLinkId}
      collectionId={collectionId}
      css={`
        /* this is needed for proper alignment in the table cell to offset the padding on the hex button component */
        margin-left: -6px;
      `}
      hex={hex}
      projectRole={projectRole}
      onChange={handleOnChange}
    />
  );
});

const MoreActionsButton = React.memo<{
  onClick?: React.MouseEventHandler;
  elementRef?: ButtonProps["ref"];
}>(function MoreActionsButton({ elementRef, onClick }) {
  return (
    <IconOnlyButton
      ref={elementRef}
      icon={<MoreMenuIcon aria-hidden={true} svgOnly={true} />}
      minimal={true}
      small={true}
      text="View more actions"
      onClick={onClick}
    />
  );
});

const StyledHexNonIdealState = styled(HexNonIdealState)`
  display: flex;
  flex-direction: row;
  border: none;
`;

export const CustomLoadingCellRenderer = React.memo<
  CustomLoadingCellRendererProps & {
    errorEmptyStateParams: {
      icon: HexNonIdealStateProps["icon"];
      title: HexNonIdealStateProps["title"];
    };
  }
>(function CustomLoadingCellRenderer({
  errorEmptyStateParams: { icon, title },
  node: { failedLoad = false, rowIndex },
}) {
  const isFreshFetch = rowIndex === 0;
  if (isFreshFetch) {
    return null;
  }

  if (failedLoad) {
    return (
      <LoadingCellWrapper>
        <HexNonIdealStateContainer role="presentation">
          <StyledHexNonIdealState
            $minimal={true}
            $small={true}
            aria-atomic="true"
            aria-live="polite"
            icon={icon}
            title={title}
          />
        </HexNonIdealStateContainer>
      </LoadingCellWrapper>
    );
  }

  return (
    <LoadingCellWrapper>
      <HexSpinner size={SpinnerSize.SMALL} />
      <ScreenReaderOnly>Loading ...</ScreenReaderOnly>
    </LoadingCellWrapper>
  );
});

export const HumanDateCellRenderer = React.memo<{ value: string | null }>(
  function HumanDateCellRenderer({ value }) {
    if (value == null) {
      return null;
    }
    return (
      <TableCellText>
        <HumanDate date={value} title={true} />
      </TableCellText>
    );
  },
);

export const TrashDateCellRenderer = React.memo<{
  data?: HexRow2Fragment | CollectionHexLink2Fragment;
  value: string | null;
}>(function TrashDateCellRenderer({ data, value: dateTrashed }) {
  if (dateTrashed == null || data == null) {
    return <NullishCellValue />;
  }

  const { hexType }: HexRow2Fragment | UnknownHexRowFragment =
    isCollectionHexLink(data) ? data.safeOrUnknownHex : data;

  return (
    <HardDeleteWarningTag
      dateTrashed={dateTrashed}
      isComponent={hexType === HexType.COMPONENT}
    />
  );
});

export const CreatorCellRenderer = React.memo<{
  data: HexRow2Fragment | CollectionHexLink2Fragment;
  value: { imageUrl: string | undefined; name: string } | null;
}>(function CreatorCellRenderer({ value }) {
  if (value == null) {
    return <NullishCellValue />;
  }

  const text = value.name[0];

  return (
    <div
      css={`
        display: flex;
        align-items: center;
        column-gap: 6px;
      `}
    >
      <Avatar
        active={true}
        altText={value.name}
        imageUrl={value.imageUrl}
        size={20}
        text={text}
      />

      <TableCellText>{value.name}</TableCellText>
    </div>
  );
});

export interface SelectedCellRendererProps
  extends ICellRendererParams<ProjectsTableSafeOrUnknownHexFragment> {}

export const SelectedCellRenderer = React.memo(function SelectedCellRenderer({
  data,
  node,
}: SelectedCellRendererProps) {
  const [isSelected, setIsSelected] = useState(() => node.isSelected());
  const [isSelectable, setIsSelectable] = useState(() => node.selectable);

  useEffect(() => {
    const onSelectableListener = (): void => {
      setIsSelectable(node.selectable);
    };
    const onSelectedListener = (): void => {
      setIsSelected(node.isSelected());
    };
    node.addEventListener("selectableChanged", onSelectableListener);
    node.addEventListener("rowSelected", onSelectedListener);

    return () => {
      node.removeEventListener("selectableChanged", onSelectableListener);
      node.removeEventListener("rowSelected", onSelectedListener);
    };
  }, [node]);

  const checkbox = (
    <HexCheckbox
      checked={isSelected}
      className="hover-checkbox"
      readOnly={isSelectable}
      // eslint-disable-next-line react/jsx-no-bind -- no need to memo
      onClick={(evt) => {
        // blueprint checkboxes end up emitting two native click events
        // one on the actual input and one on the label
        // so we need to prevent one of them so we don't immediately deselect
        // @see https://github.com/palantir/blueprint/issues/3466
        //
        // the actual click event is handled by `handleOnCellClicked` in ProjectsTable
        evt.preventDefault();
      }}
    />
  );

  if (isSelected || data == null) {
    return checkbox;
  }

  const icon = (
    <ProjectCardTypeIcon
      className="hover-icon"
      hexType={data.hexType}
      isPublished={
        data.__typename !== "UnknownHex" && data.lastPublishedVersion != null
      }
      isUnknownHex={data.__typename === "UnknownHex"}
    />
  );

  if (data.__typename === "UnknownHex") {
    return (
      <HexTooltip
        content={`This ${
          data.hexType === HexType.COMPONENT ? "component" : "project"
        } has not been shared with you`}
        disabled={false}
        targetTagName="span"
      >
        {icon}
      </HexTooltip>
    );
  }

  return (
    <CheckboxOrIcon>
      {icon}
      {checkbox}
    </CheckboxOrIcon>
  );
});

const CheckboxOrIcon = styled.span`
  .hover-checkbox {
    display: none;
  }

  .ag-row-hover & {
    .hover-checkbox {
      display: block;
    }

    .hover-icon {
      display: none;
    }
  }
`;

const CollectionButton = React.memo<{
  id: CollectionId;
  name: string;
  emoji: string | null;
}>(function CollectionButton({ emoji, id, name }) {
  const history = useHistory();
  const openCollection = useCallback(() => {
    Routes.push(history, Routes.COLLECTION, {
      collectionId: id,
      collectionName: name,
    });
  }, [history, id, name]);

  return (
    <HexButton key={id} minimal={true} subtle={true} onClick={openCollection}>
      <Txt
        css={css`
          display: flex;
          gap: 4px;
        `}
        ellipsize={true}
        fontColor="muted"
        fontSize="small"
      >
        {emoji != null && (
          <CollectionEmoji $small={true}>{emoji}</CollectionEmoji>
        )}
        {name}
      </Txt>
    </HexButton>
  );
});

export const CollectionsCellRenderer = React.memo<{
  data?: HexRow2Fragment | CollectionHexLink2Fragment;
}>(function CollectionsCellRenderer({ data }) {
  if (data == null) {
    return <NullishCellValue />;
  }

  const hex: HexRow2Fragment | UnknownHexRowFragment = isCollectionHexLink(data)
    ? data.safeOrUnknownHex
    : data;

  if (hex.__typename === "UnknownHex") {
    return <NullishCellValue />;
  }

  const collectionHexLinks = hex.safeCollectionHexLinks;
  if (collectionHexLinks == null || collectionHexLinks.length === 0) {
    return <NullishCellValue />;
  }

  let linksToRender = collectionHexLinks;
  let plusMore = null;
  if (collectionHexLinks.length > OVERFLOW_TOOLTIP_COUNT) {
    linksToRender = collectionHexLinks.slice(0, OVERFLOW_TOOLTIP_COUNT);
    const linksForTooltip = collectionHexLinks.slice(OVERFLOW_TOOLTIP_COUNT);
    plusMore = (
      <MoreTooltip
        content={
          <MoreTooltipContentsWrapper>
            {linksForTooltip.map(({ collection: { emoji, id, name } }) => (
              <CollectionButton key={id} emoji={emoji} id={id} name={name} />
            ))}
          </MoreTooltipContentsWrapper>
        }
        hoverOpenDelay={200}
        interactionKind="hover"
        position="bottom"
      >
        <CollapsedHexTag intent={Intent.NONE}>
          +{linksForTooltip.length}
          <ScreenReaderOnly>More collections</ScreenReaderOnly>
        </CollapsedHexTag>
      </MoreTooltip>
    );
  }

  const sortedLinks = orderBy(linksToRender, ["collection.name"]);

  return (
    <div
      css={css`
        display: inline-flex;
        gap: 4px;
        align-items: center;
      `}
    >
      {sortedLinks.map(({ collection: { emoji, id, name } }) => (
        <CollectionButton key={id} emoji={emoji} id={id} name={name} />
      ))}
      {plusMore}
    </div>
  );
});
